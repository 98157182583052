<template>
  <div>
    <jaya-connection-layout text="Bienvenue sur Hortilio, l'application gratuite 'Jardinage et Biodiversité'.
    Renseignez vos informations pour créer un nouveau compte">
      <validation-observer v-slot="{invalid, handleSubmit}">
        <b-form @submit.prevent="handleSubmit(register)">
          <b-row>

            <!-- Email -->
            <b-col cols="12">
              <b-form-group>
                <JayaTooltip class="mt-1" title="Email">
                  <label>
                    <span class="font-small-4">Email </span>
                    
                    <feather-icon
                        icon="InfoIcon"
                        class="text-info"
                        size="16"/>
                  </label>
                  <div slot="text">
                    Votre email est l'identifiant unique de votre compte. Vous ne pouvez créer qu'un seul compte avec
                    un email donné. Il permet aussi de valider votre compte à l'étape suivante, et ainsi de s'assurer
                    que l'inscription vient d'un humain, et non d'un robot !
                  </div>
                </JayaTooltip>
                <validation-provider v-slot="{errors}" immediate rules="required|email">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon"/>
                    </b-input-group-prepend>

                    <b-form-input v-model="email"
                                  autofocus
                                  placeholder="Email"
                                  type="email"/>
                  </b-input-group>
                  <b-form-text class="text-danger text-right">{{ errors[0] }}</b-form-text>
                  <b-form-text v-if="registerErrors.email === 'allreadyUsedEmail'"
                               class="text-danger text-right">
                    Il existe déjà un compte à cette adresse
                  </b-form-text>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Password -->
            <b-col cols="12">
              <b-form-group>
                <JayaTooltip class="mt-1" title="Mot de passe et Confirmation">
                  <label>
                    <span class="font-small-4">Mot de passe et Confirmation </span>
                    
                    <feather-icon
                        icon="InfoIcon"
                        class="text-info"
                        size="16"/>
                  </label>
                  <div slot="text">
                    Le mot de passe sécurise l'accès à votre compte. Il doit contenir au moins 8 caractères
                    dont une lettre et un chiffre, ne pas être trop simple et ne pas être trop proche de votre nom
                    d'utilisateur ou email.
                  </div>
                </JayaTooltip>
                <validation-provider v-slot="{errors}" immediate rules="required" vid="password">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LockIcon"/>
                    </b-input-group-prepend>
                    <b-form-input v-model="password"
                                  :type="passwordFieldType"
                                  placeholder="Mot de passe"/>
                  </b-input-group>
                  <b-form-text class="text-danger text-right">{{ errors[0] }}</b-form-text>
                </validation-provider>

                <validation-provider v-slot="{errors}" immediate rules="required|confirmed:password">
                  <b-input-group class="mt-50">
                    <b-input-group-prepend is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"/>
                    </b-input-group-prepend>
                    <b-form-input v-model="password_confirm"
                                  :type="passwordFieldType"
                                  placeholder="Confirmation"/>
                  </b-input-group>
                  <b-form-text class="text-danger text-right">{{ errors[0] }}</b-form-text>
                  <b-form-text v-if="registerErrors.password === 'tooShort'"
                               class="text-danger text-right">
                    Votre nouveau mot de passe doit contenir au moins 8 caractères
                    dont une lettre et un chiffre
                  </b-form-text>
                  <b-form-text v-if="registerErrors.password === 'tooCommonPassword'"
                               class="text-danger text-right">
                    Votre mot de passe est trop simple.
                  </b-form-text>
                  <b-form-text v-if="registerErrors.password === 'numeric'"
                               class="text-danger text-right">
                    Votre mot de passe ne peut pas être composé exclusivement de chiffres.
                  </b-form-text>
                  <b-form-text v-if="registerErrors.password === 'tooSimilar'"
                               class="text-danger text-right">
                    Votre mot de passe est trop proche de votre nom d'utilisateur ou email.
                  </b-form-text>
                  <b-form-text v-if="registerErrors.password === 'badPassword'"
                               class="text-danger text-right">
                    Votre mot de passe ne respecte pas notre politique de sécurité, veuillez en essayer un autre, de
                    plus de 8 caractères dont au moins une lettre et un chiffre
                  </b-form-text>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- ZipCode -->
            <b-col cols="12">
              <b-form-group>
                <JayaTooltip class="mt-1" title="Code postal">
                  <label>
                    <span class="font-small-4">Code postal </span>
                    
                    <feather-icon
                        icon="InfoIcon"
                        class="text-info"
                        size="16"/>
                  </label>
                  <div slot="text">
                    Le code postal du jardin permet de vous rattacher à des collectivités territoriales (commune,
                    métropole, département) - si présentes sur Hortilio.  Grâce à cette information,
                    vous pouvez accédez à du contenu spécifique à votre territoire : lieux, évènements, espèces
                    végétales locales... Lorsque le code postal correspond à une collectivité existante sur Hortilio,
                    nous lui transmettons des statistiques anonymes sur les pratiques, le contenu et les aménagaments
                    des jardins privés de son territoire. Vous pouvez consulter les Conditions Générales d'utilisation,
                    et la Charte d'Utilisation des Données Personnelles pour en savoir plus.
                  </div>
                </JayaTooltip>
                <validation-provider v-slot="{errors}" immediate rules="validZipCode">
                  <b-input-group class="flex-nowrap">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HomeIcon"/>
                    </b-input-group-prepend>
                    <vue-simple-suggest
                        v-model="zipCode"
                        :debounce="200"
                        :list="zipCodeSearch"
                        :max-suggestions="30"
                        :min-length="2"
                        :nullable-select="false"
                        :prevent-submit="true"
                        class="flex-grow-1"
                        display-attribute="display"
                        placeholder="Code postal"
                        value-attribute="id"
                        @select="setUpZipCode"/>
                  </b-input-group>
                  <b-form-text class="text-danger text-right">{{ errors[0] }}</b-form-text>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Name -->
            <b-col cols="12">
              <b-form-group>
                <JayaTooltip class="mt-1" title="Prénom et Nom">
                  <label>
                    <span class="font-small-4">Prénom et Nom </span>
                    <feather-icon
                        icon="InfoIcon"
                        class="text-info"
                        size="16"/>
                  </label>
                  <div slot="text">
                    Votre nom et prénom est optionnel. Il sert simplement à vous identifier auprès des autres
                    utilisateurs sur le forum de Hortilio. Vous pouvez tout à fait mettre un pseudonyme ou un surnom.
                  </div>
                </JayaTooltip>
                <validation-provider v-slot="{errors}" name="Prénom" rules="maxlength:30">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon"/>
                    </b-input-group-prepend>
                    <b-form-input v-model="first_name"
                                  placeholder="Prénom"
                                  type="text"/>
                  </b-input-group>
                  <b-form-text class="text-danger text-right">{{ errors[0] }}</b-form-text>
                  <b-form-text v-if="registerErrors.first_name === 'badFirstName'"
                               class="text-danger text-right">
                    Désolé, le prénom renseigné est trop long: maximum 30 caractères
                  </b-form-text>
                </validation-provider>
                <validation-provider v-slot="{errors}" name="Nom" rules="maxlength:150">
                  <b-input-group class="mt-50">
                    <b-form-input v-model="last_name"
                                  placeholder="Nom"
                                  type="text"/>
                  </b-input-group>
                  <b-form-text class="text-danger text-right">{{ errors[0] }}</b-form-text>
                  <b-form-text v-if="registerErrors.last_name === 'badLastName'"
                               class="text-danger text-right">
                    Désolé, le nom renseigné est trop long: maximum 150 caractères
                  </b-form-text>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Read CGU-->
            <b-col cols="12">
              <b-form-group class="d-flex justify-content-start">
                <validation-provider v-slot="{invalid, errors}"
                                     :rules="{required: { allowFalse: false }}" immediate>
                  <b-form-checkbox v-model="isTermsConditionAccepted">
                    J'accepte les
                    <a @click="popUpCgu===true" class="text-primary">
                      Conditions Générales d'Utilisation
                    </a>
                    <b-form-text v-if="invalid" class="text-danger"> {{
                        errors[0]
                      }}
                    </b-form-text>
                  </b-form-checkbox>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Read Charter-->
            <b-col cols="12">
              <b-form-group class="d-flex justify-content-start">
                <validation-provider v-slot="{invalid, errors}"
                                     :rules="{required: { allowFalse: false }}" immediate>
                  <b-form-checkbox v-model="isCharterAccepted">
                    J'accepte la
                    <a @click="popUpCharter===true" class="text-primary">
                      Charte de Protection des données personnelles
                    </a>
                    <b-form-text v-if="invalid" class="text-danger"> {{
                        errors[0]
                      }}
                    </b-form-text>
                  </b-form-checkbox>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Buttons-->
            <b-col class="d-flex justify-content-around flex-wrap" cols="12">
              <b-button class="m-25 flex-grow-1" to="/login" variant="outline-primary">
                J'ai déjà un compte
              </b-button>
              <b-button
                  :disabled="invalid"
                  class="m-25 flex-grow-1"
                  type="submit"
                  variant="primary">
                Valider mon inscription
              </b-button>
            </b-col>

            <!-- Social connect-->
            <b-col v-if="!isIos && show_social_login" class="d-flex justify-content-around flex-wrap" cols="12">
              <jaya-divider class="col-12" color="dark" text="Se connecter avec"/>
              <b-button class="mb-1 google-button col-7" @click="socialUrl('google')">
                <img :src="require('@/assets/images/pages/login/google-white.png')"
                     alt="Logo Google"
                     class="cursor-pointer mr-1"
                     style="height: 1rem; background: #db4a39 !important">
                <span class="align-middle">Google</span>
              </b-button>

              <b-button class="mb-1 facebook-button col-7" style="background: #3b5998 !important; border: 0"
                        @click="socialUrl('facebook')">
                <feather-icon class="mr-1" icon="FacebookIcon"/>
                <span class="align-middle">Facebook</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </jaya-connection-layout>

    <b-modal
        v-model="popUpMoreInfo"
        cancel-title="Annuler"
        modal-class="modal-primary"
        ok-only
        ok-title="Ok"
        title="Il reste une dernière étape !">
      <p> Un email vous a été envoyé à l'adresse : <strong>{{ display_email }}</strong></p>
      <p> Il est possible qu'il soit arrivé dans vos spams : pensez à les vérifier !</p>
      <p> Si après quelques minutes, vous n'avez toujours pas reçu de mail,<span
          class="cursor-pointer font-medium text-primary" @click="resendEmail"> cliquez ici </span> pour un
        nouvel envoi.</p>
    </b-modal>

    <b-modal
        v-model="popUpCgu"
        modal-class="modal-primary"
        ok-only
        scrollable
        hide-header-close
        ok-title="Retour"
        title="Conditions Générales d'Utilisation">
      <GdprDocViewer doc_type="general-conditions"/>
    </b-modal>

    <b-modal
        v-model="popUpCharter"
        modal-class="modal-primary"
        ok-only
        scrollable
        ok-title="Retour"
        title="Charte de Protection des Données Personnelles"
    >
      <GdprDocViewer doc_type="gdpr-charter"/>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormText,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BRow,
} from "bootstrap-vue"
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {confirmed, email, required} from 'vee-validate/dist/rules'
import GdprDocViewer from "@/views/gdpr/GdprDocViewer";

extend('required', {...required, message: 'Ce champ est requis'});
extend('email', {...email, message: 'Email invalide'});
extend('confirmed', {...confirmed, message: 'Les mots de passe ne correspondent pas'});
extend('maxlength', {
  validate(value, args) {
    return value.length <= args.length;
  },
  params: ['length'],
  message: 'Le champ "{_field_}" doit faire moins de {length} caractères'
});
extend('minlength', {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ['length'],
  message: 'Le champ "{_field_}" doit faire au moins {length} caractère(s)'
});

export default {
  components: {
    JayaConnectionLayout,
    VueSimpleSuggest,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormText,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
    GdprDocViewer,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      email: "",
      password: "",
      password_confirm: "",
      isTermsConditionAccepted: false,
      isCharterAccepted: false,
      first_name: "",
      last_name: "",
      zipCode: "",
      editingZipCode: null,
      popUpMoreInfo: false,
      popUpCgu: false,
      popUpCharter: false,
      display_email: "",
      registerErrors: {},
      user_origin: null,
      show_social_login: true
    };
  },
  mounted() {
    if (this.$route.query.creation_code) {
      this.user_origin = this.$route.query.creation_code
      if (this.user_origin.includes("cdf_potager")) {
        this.show_social_login = false
      }
    }

    const _this = this
    extend('validZipCode', {
      validate() {
        return _this.editingZipCode && _this.editingZipCode.id && _this.editingZipCode.display === _this.zipCode
      },
      message: 'Code postal invalide : utilisez les suggestions'
    })
  },
  computed: {
    isIos() {
      return window.cordova && window.cordova.platformId === "ios"
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    register() {
      this.$loading(true)
      // If form is not validated or user is already login return
      const payload = {
        username: this.email,
        email: this.email,
        password: this.password,
        password_confirm: this.password_confirm,
        last_name: this.last_name,
        first_name: this.first_name,
      }
      if (this.editingZipCode) {
        payload.zip_code = this.editingZipCode.id
      }
      if (this.user_origin) {
        payload.user_creation_origin = this.user_origin
      }
      payload.redirect_uri = process.env.VUE_APP_REDIRECT_URI_VERIFY

      this.$store.dispatch("account/register", payload)
          .then(() => {
            this.popUpMoreInfo = true
            this.display_email = this.email
            this.email = ""
            this.first_name = ""
            this.last_name = ""
            this.zipCode = ""
            this.editingZipCode = null
            this.password = ""
            this.password_confirm = ""
            this.isTermsConditionAccepted = false
            this.isCharterAccepted = false
            this.registerErrors = {}
            this.$loading(false)
          })
          .catch(errors => {
            this.registerErrors = errors
            this.$loading(false)
          })
    },
    resendEmail() {
      let payload = {email: this.display_email}
      this.$store.dispatch("account/resend_validation_email", payload)
          .then(() => {
            this.$notify(
                {
                  title: "Renvoi effectué",
                  text: "Un email vous a été renvoyé à l'adresse : " + this.display_email,
                  time: 4000,
                  color: "primary"
                }
            )
          })
    },
    socialUrl(provider) {
      let state = new Uint32Array(1)
      // https://developer.mozilla.org/fr/docs/Web/API/RandomSource/getRandomValues says that these number are strong enough to get crypto
      window.crypto.getRandomValues(state)
      let url = process.env.VUE_APP_BACKEND_URL + "/accounts/social-auth?provider=" + provider
          + "&redirect_uri=" + process.env.VUE_APP_REDIRECT_URI
          + "&state=" + state[0].toString()
      localStorage.setItem("socialState", state[0].toString())
      if (process.env.VUE_APP_USE_TOKEN) {
        // Force using external browser
        window.cordova.InAppBrowser.open(url, '_system')
      } else {
        window.location.href = url
      }
    },
    zipCodeSearch(searchText) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("zipCode/search", {search: searchText, limit: 30})
            .then(results => {
              //make nam + zipcode attribute
              resolve(results.data.results.map(obj => ({...obj, display: obj.zip_code + ' - ' + obj.name})))
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    setUpZipCode(zipCode) {
      if (zipCode && zipCode.id) {
        this.editingZipCode = zipCode
      } else {
        this.editingZipCode = null
      }
    },
  }
};
</script>

<style lang="scss">

.vue-simple-suggest.designed .input-wrapper input {
  border: 1px solid #d8d6de !important;
  border-radius: 0 5px 5px 0;
  padding: 0.438rem 1rem;
}

.vue-simple-suggest.designed .suggestions {
  max-height: 300px;
  overflow-y: scroll;
}

.vue-simple-suggest.designed.focus .input-wrapper input {
  border: 1px solid var(--primary) !important;
}

.vue-simple-suggest ::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}


.google-button {
  background: #db4a39 !important;
  border-width: 0;
}

.google-button:hover {
  box-shadow: 0 8px 25px -8px #db4a39 !important;
}

.facebook-button {
  background: #3b5998 !important;
  border-width: 0;
}

.facebook-button:hover {
  box-shadow: 0 8px 25px -8px #3b5998 !important;
}

</style>
